import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
       } 
};
class OrganizationService {

    list(perPage:number,page:number,search_keywords:any, sort:any, column:string, order:string){
        let dataURL:string =  API_URL +`/organization/list?list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL,header); // use axios library getting request data
    }  
    store(data:any, logo:any) {
        let dataURL:string = API_URL + "/organization/store";
        if(logo != ""){
            data.logo = logo;
        }
        return axios.post(dataURL,data, header);
    }
    update(data:any , logo:any ,id:any) {
        let dataURL:string = API_URL + "/organization/update/"+id;
        if(logo != ""){
            data.logo = logo;
        }
        return axios.post(dataURL,data, header);
    }
    showOrg(org_id:any){
        let dataURL:string = API_URL +`/organization/detail/${org_id}`;
        return axios.get(dataURL, header);
    }
    deleteList(data:any){
        let dataURL:string = API_URL+"/organization/list/destroy";
        return axios.post(dataURL,data,header);
    }
    
}

export default new OrganizationService();
import React, {useEffect, useState} from 'react';
import PersonService from "../../services/PersonService";
import AuthService from "../../services/AuthService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import BulkMailModal from "../../components/common/BulkMailModal";
import CustomerMailModal from "../../components/common/CustomerMailModal";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Link} from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import {SendBulkMail, SendContactMail} from "../../models/Person";
import {SendCustomerMail} from "../../models/Person";
import {useLocation} from "react-router-dom";
import Loader from "../../components/common/Loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";

interface IState{
    persons : [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    creator_list : [];
    belong_type_list : [];
    isChecked : boolean;
    send_mail_arr : SendBulkMail;
    send_contact_mail_arr : SendContactMail;
    send_customer_mail_arr : SendCustomerMail;
    loading : boolean;
}
interface IProps{}
let PersonList:React.FC<IProps> = () => {
    // const [isChecked, setChecked] = useState(false);


  

    // const selectAll = function(e:any){
    //     setState({
    //         ...state,
    //         isChecked: true
    //     })

        
    //     // if(isChecked === true){
    //     //     setChecked(false);
    //     // }else{
    //     //     setChecked(true);
    //     // }
    // }
    const row : string[] = [];
    const [checkedRows, setCheckedRows] = useState(row);
    const [belong_type, setBelongType] = useState("全て");  
    const [info_type, setInfoType] = useState("base");  
    const [creator, setCreator] = useState("全て");  
    const [baseChecked,setBaseChecked] = useState<boolean>(true);
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    const [search_skill, setSearchSkill] = useState(""); 


    const defaultcolumns = [
        { 
            name :'person_id', 
            label : "ID"
        },
        { 
            name :'InitialName', 
            label : "イニシャル",
            class : 'longText',
            width : 500,
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "130px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                    );
                }
            }
        },
        { 
            name :'assign_status', 
            label : "ステータス",
            class : "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "130px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
            }
        },
        { 
            name :'gender', 
            label : "性別",
            options:{
                setCellProps: () => ({ style: { maxWidth: "5px"}}),
            }
        },
        { 
            name :'country', 
            label : "国籍",
            class : 'longText',
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "80px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
            }
        },
        { 
            name :'age', 
            label : "年齢",
            options:{
                setCellProps: () => ({ style: { maxWidth: "130px"}}),
            },
            sortable: false,
        },
        { 
            name :'nearest_station', 
            label : "最寄り駅",
            options:{
                setCellProps: () => ({ style: { textAlign:"center", maxWidth: "80px"}}),
            }
        },
        { 
            name :'prof', 
            label : "経験",
            options:{
                setCellProps: () => ({ style: { maxWidth: "80px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    value = (value)?value+"年":""
                    return(value);
                }
            },
            sortable: false,
        },
        { 
            name :'arrival', 
            label : "来日",
            options:{
                setCellProps: () => ({ style: { maxWidth: "5px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    value = (value)?value+"年":""
                    return(value);
                }
            },
           sortable: false,

        },
        { 
            name :'japanese_level',
            label : "日本語レベル",
            class: "longText",
            options:{
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
            }
        },
        { 
            name :'english_level', 
            label : "英語レベル",
            class: "longText",
            options:{
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
            }
        },
        { 
            name :'skill', 
            label : "スキル",
            class: "longText",
            options:{
                setCellProps: () => ({ style: { maxWidth: "200px",marginLeft:"100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value !== null && value.length >= 22)? value.substring(0, 22)+"...": value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
            }
        },
        { 
            name :'response_phase', 
            label : "対応フェーズ",
            class: "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "120px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value !== null && value !== undefined && value.length >= 10)? value.substring(0, 10)+"...": value;
                    // value = (value !== null && value !== undefined)? value.substring(0, 10)+"...": value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        { 
            name :'workstart_date', 
            label : "新規稼働日",
            options:{
                setCellProps: () => ({ style: { maxWidth: "80px"}}),
            }
        },
        // { 
        //     name :'person_cost', 
        //     label : "コスト",
        //     display:(login_user.role==7)?true:false
        // },
        {
            name :'proposal_price', 
            label : "希望単価",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "80px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(value > 99999){
                        // value = Math.ceil(value/10000)+"万";
                        value = (value/10000).toFixed(2);
                        let pos = value.lastIndexOf('.');
                        let point = parseInt(value.substring(pos, 2));
                        if(point > 0){
                            value = value  +"万";
                        }else{
                            value =  value.substring(0, pos)+"万";
                        }
                        
                    }else{
                        value = (value)?value: "";
                    }
                   return (value);
                    },
                },
        },
        { 
            name :'remarks', 
            label : "特記事項",
            class: "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
                    // value = (value !== null && value!==undefined)? value.substring(0, 10)+"...": value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        { 
            name :'priority', 
            label : "優先順位",
            options:{
                setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "130px"}}),
            }
        },
        { 
            name :'update_date', 
            label : "更新日",
            class : "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: {maxWidth:"110px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // const date = value;
                    // const day = date.getDate();
                    // const month = date.getMonth() + 1;
                    // const year = date.getFullYear();                    
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    // value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
                    // value = (value !== null && value!==undefined)? value.substring(0, 10)+"...": value;
                    // return(value);
                    },
            },
        }];
    
    const belongcolumns = [
        { 
            name :'person_id', 
            label : "ID"
        },
        { 
            name :'InitialName', 
            label : "イニシャル",
            class : 'longText',
            width : 500,
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "120px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                    );
                }
            }
        },
        { 
            name :'full_name', 
            label : "漢字氏名",
            options: {
                setCellProps: () => ({ style: { maxWidth: "120px"}}),
            }
        },
        { 
            name :'belong_type', 
            label : "所属種別",
            options: {
                setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "130px"}}),
            }
        },
        { 
            name :'belong_company_id', 
            label : "所属先ID",
            options:{
                setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "120x",textAlign:"center"}}),
            }
        },
        { 
            name :'company-company', 
            label : "所属先",
            class : "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: {maxWidth:"100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    // value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
                    // value = (value !== null && value!==undefined)? value.substring(0, 10)+"...": value;
                    // return(value);
                    },
            }
        },
        { 
            name :'belong_contact_nm', 
            label : "所属先担当者",
            class : "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: {maxWidth:"100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
            }
            //     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
            //         // value = (value !== null && value.length >= 22)? value.substring(0, 22)+"...": value;
            //         // return(value);
            //         return(
            //             <div className='longText' title={value}>
            //                 {value}
            //             </div>
            //         );
            //     }
           
        },
        { 
            name :'belong_contact_email', 
            label : "所属先担当者メール",
            setCellProps: () => ({ style: { maxWidth: "80px"}}),
        },
        { 
            name :'belong_contact_tel', 
            label : "所属先担当者電話",
            class : "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: {maxWidth:"100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
            }
        },
        { 
            name :'person_contact_info', 
            label : "個人連絡先",
            class : "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: {maxWidth:"100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    // value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
                    // value = (value !== null && value!==undefined)? value.substring(0, 10)+"...": value;
                    // return(value);
                    },
            }
        },
        { 
            name :'person_cost', 
            label : "コスト",
            options:{
                setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "120x"}}),
            }
        },
        { 
            name :'priority', 
            label : "優先順位",
            options:{
                setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "130px"}}),
            }
        },
        { 
            name :'update_date', 
            label : "更新日",
            class : "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: {mixWidth:"100px",maxWidth:"130px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    // value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
                    // value = (value !== null && value!==undefined)? value.substring(0, 10)+"...": value;
                    // return(value);
                    },
            }
        }
    ];
   

    // const getMuiTheme = () =>
    // createTheme({
    //     components: {
    //     MUIDataTable: {
    //         styleOverrides:{
    //             responsiveScroll: {
    //                 maxHeight: 'none',
    //               },
    //         }
    //     },
    //     MuiPaper: {
    //         styleOverrides:{
    //             root: {
    //                 width: "100%",
    //                 marginTop: "5%",
    //                 // // marginLeft: "5%",
    //                 // // marginBottom: "20%"
    //             }
    //         }
    //     },  
    //     MUIDataTableHeadCell: {
    //             styleOverrides:{
    //               root: {
    //                   backgroundColor: "rgb(193,193,193)",
    //                   padding: '0px'
    //               }
    //             }
    //     },
    //     // MuiTableCell: {
    //     //     head: {
    //     //         backgroundColor: "red !important"
    //     //     }
    //     // }

    //     MuiTableCell: {
    //         styleOverrides:{
    //             head: {
    //                     color : 'white',
    //                     backgroundColor: "red !important"
    //             }
    //         }
    //     },
    //       MUIDataTableBodyCell: {
    //         styleOverrides:{
    //           root: {
    //               backgroundColor: "#f1f1f1",
    //               width: "100%",
    //               padding: '0px'
    //           }
    //         }
    //       }
    //     }
    //   })

    let [state , setState] = useState<IState>({
        persons : [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        creator_list : [],
        belong_type_list : [],
        isChecked: false,
        send_mail_arr : {
            personId : "",
            mail_to : "",
            mail_subject : "",
            mail_message : ""
        },
        send_contact_mail_arr : {
            mail_to : "",
            mail_subject : "",
            mail_cc : "",
            mail_message : ""
        },
        send_customer_mail_arr: {
            personId :  "",
            mail_to :"",
            mail_bcc :"",
            mail_cc : (login_user.role ==7)?login_user.email:"sales@floboard.co.jp",
            mail_subject : "",
            mail_message : "",
            attachment : ""
        },
        loading: false
    });

    const [customer, setCustomer] = useState("");
    const [salerchecked, setsalerChecked] = useState<boolean>(false);
    const [saler, setSaler] = useState("");
    const [search, setSearch] = useState("");
    const [active, setActive] = useState("available"); 
    const [status, setStatus] = useState("available");
    const [mailmessage, setMailMessge] = useState("");
    const result : string[] = ['status'];
    const [filter_list, setFilterList] = useState(result); 
    const [customerchecked, setCustomerChecked] = useState<boolean>(false);
    const [columnVisible, setColumnVisible] = React.useState(defaultcolumns);
    const skill : string[] = [];
    const [skill_list, setSkillList] = useState(skill); 
    const [sort, setSort] = useState("0");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const [open, setOpenModal] = React.useState(false);
    const handleClose = () => setOpenModal(false); 
    const [bulkOpen, setOpenBulkModal] = React.useState(false);
    const [contactMailOpen, setContactMailOpen] = React.useState(false);
    const handleBulkModalClose = () => setOpenBulkModal(false);
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const handlecontactMailModalClose = () => setContactMailOpen(false);
    const [person_count , setPersonCount] = useState(0);
    const [otsu_not_public_count , setOtsuNotPublicCount] = useState(0);
    const [otsu_public_count , setOtsuPublicCount] = useState(0);  
    const [checked, isChecked] = useState(false);
    const [active_infotype, setActiveInfoType] = useState("base");

    // const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: `calc(100% - 50%)`,
    //     height:  `calc(100% - 10%)`,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };
    
    // const mobile_style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     // width: 400,
    //     // height:  700,
    //     width: `calc(100% - 10%)`,
    //     height:  `calc(100% - 5%)`,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    // };

      let status_list :any[] = [];
      if(login_user.role ==3){
          status_list = [['available','営業可'], ['not_start','未着手'],['assigning','割り当て中'],['proposaling', '提案中'],['interview','面談設定中'],
             ['wait_result','結果待ち中']];
      }else if(login_user.role ==8){
          status_list = [['available','営業可'], ['not_start','未着手'],['proposaling', '提案中'],['interview','面談設定中'],
             ['wait_result','結果待ち中']];
      }else
      {
          status_list = [['all', 'すべて'] , ['unavailable','営業不可'], ['available','営業可'], ['not_start','未着手'],['assigning','割り当て中'],['proposaling', '提案中'],['interview','面談設定中'],
             ['wait_result','結果待ち中']];
      }
      // const status_list = [['all', 'すべて'] , ['unavailable','営業不可'], ['available','営業可'], ['not_start','未着手'],['assigning','割り当て中'],['proposaling', '提案中'],['interview','面談設定中'],
      //   ['wait_result','結果待ち中']];
      
      let status_list1 :any[] = [];
      if(login_user.role !=7 && login_user.role!=8){
          status_list1 = [['not_public','非公開']];
      }
  
      const status_list2 = [['now_available','即日稼働可'],['next_month_available','翌月稼働可'],['next_two_month_available','翌々月稼働可'],['release','退場予定'],['finish_release','退場済み']];
      
      let status_list3 :any[] = [];
      if(login_user.role == 4 || login_user.role == 9){
          status_list3 = [['unit_price_set','希望単金設定待ち']];
      }

    const getData = async (filter_list:any, status:any, belong_type:string,info_type:string,creator:any, perPage:number, page:number, search:any,skill_list:any, sort:any, column:string, order:string, search_skill:any) => {
        if(column == "age"){
            column= "birthday";
        }else if(column == "prof"){
            column= "prof_start_ym";
        }else if(column == "arrival"){
            column= "arrival_in_jp_ym";
        }
        setState({...state,loading :true,})
        console.log(`Person List start :${(new Date()).getTime()}`)

        await PersonService.list(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill).then((response)=>{
            setState({
                ...state,
                persons: response.data.data,
                // columns : response.data.columns,
                page : response.data.current_page,
                perPage : response.data.per_page,
                totalRows : response.data.total,
                creator_list : response.data.select_options.creator,
                belong_type_list : response.data.select_options.belong_type,
                loading:false,
            })
            setPersonCount(response.data.person_count);
            setOtsuPublicCount(response.data.otsu_public_count);
            setOtsuNotPublicCount(response.data.otsu_not_public_count);
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page){
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
               }
            setState({
                ...state,
                errorMessage:error.message,
                loading:false
            })
        })
        console.log(`Person List end :${(new Date()).getTime()}`)

    }

    let {persons, perPage, page, column, order, totalRows,creator_list, belong_type_list,send_mail_arr, send_contact_mail_arr,send_customer_mail_arr, loading , errorMessage} = state;

    const selectAll = (e:any) => {
        if(checked == false){
            isChecked(true);
            let ids : any[] = [];
            // cases.forEach((element, index) => {
            //     ids.push(element["case_id"]);
            //     setCheckedRows(ids);
            //   });
            $('input[type=checkbox][name="list_check[]').prop('checked', true).trigger('change');
        }else{
            isChecked(false);
            setCheckedRows([]);
            $('input[type=checkbox][name="list_check[]').prop('checked', false).trigger('change');
        }

        // .addClass("list_check_all");
      };
      useEffect(()=>{
        setState({...state,
            send_mail_arr : {
                personId : "",
                mail_to: "",
                mail_subject : "",
                mail_message : "",
            },
            isChecked :false,
            loading: true
        });
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }
        localStorage.removeItem("person_status");
        localStorage.removeItem("person_search");
        localStorage.removeItem("case_status");
        localStorage.removeItem("case_search");
        localStorage.removeItem("case_list_status");
        localStorage.removeItem("case_list_search");

        if(localStorage.getItem("person_list_status") != null && localStorage.getItem("person_list_search") != null){
            setActive(localStorage.getItem("person_list_status")!);
            setStatus(localStorage.getItem("person_list_status")!);
            setSearch(localStorage.getItem("person_list_search")!);
            getData(filter_list, localStorage.getItem("person_list_status")!, belong_type,info_type,creator,perPage,page,localStorage.getItem("person_list_search")!, skill_list, sort, column, order, search_skill);
        }else if(localStorage.getItem("person_list_status") != null ){
            setActive(localStorage.getItem("person_list_status")!);
            setStatus(localStorage.getItem("person_list_status")!);
            getData(filter_list, localStorage.getItem("person_list_status")!, belong_type,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill);
        }else if(localStorage.getItem("person_list_search") != null){
            setSearch(localStorage.getItem("person_list_search")!);  
            getData(filter_list, status, belong_type,info_type,creator,perPage,page,localStorage.getItem("person_list_search")!, skill_list, sort, column, order, search_skill);
        }else{
            getData(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill);
        }

        }, [perPage]);
    
  

    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(filter_list, status, belong_type,info_type,creator,perPage,page+1,search, skill_list, sort, column, order, search_skill);
    };
    let changeRowsPerPage = (perPage:any) => {
    
        getData(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill);
    };
    let emailSend = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        PersonService.bulkMailsend(send_mail_arr).then((response) => {
            window.location.reload();
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
               }
        });
    };
    let contactEmailSend = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        if(send_contact_mail_arr["mail_cc"] == null || send_contact_mail_arr["mail_cc"] ==""){
            alert("CCを入力してください。");
            return;
        }
        PersonService.contactMailSend(send_contact_mail_arr).then((response) => {
            alert("メール送信いたしました。担当者から電話をお待ちください。ありがとうございます。");
            //window.location.reload();
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    };
    let customerEmailSend = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        if(send_customer_mail_arr["mail_cc"] == null || send_customer_mail_arr["mail_cc"] ==""){
            alert("CCを入力してください。");
            return;
        }
        PersonService.customerMailsend(send_customer_mail_arr).then((response) => {
            alert("メール送信いたしました。ありがとうございます。")
            handleClose();
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
         });
    };
   
    const handleCheckbox = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;

        if(isChecked === true){
            checkedRows.push(value);
            setCheckedRows(checkedRows);
            // getData(filter_list,sale,hr,case_status, state.perPage,customer,e.target.value,state.page, search,skill_list,sort);
        }
        else{            
            const rows = checkedRows.filter(function(row){
                return row !== value; 
             });
             setCheckedRows(rows);
            //  getData(filter_list,sale,hr,case_status, state.perPage,customer,e.target.value,state.page, search,skill,sort);
        }
    }

    const handleChange  = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            // case "customer":
            //     if(e.target.checked == true){
            //         setCustomerChecked(e.target.checked)
            //         setColumnVisible(addColumns);
            //     }else
            //     {
            //         setCustomerChecked(e.target.checked)
            //         setColumnVisible(defaultcolumns);
            //     }
            //     break;
            // case "saler":
            //     if(e.target.checked == true){
            //         setsalerChecked(e.target.checked);
            //         setColumnVisible(addsalercolumns);
            //     }else
            //     {
            //         setsalerChecked(e.target.checked)
            //         setColumnVisible(defaultcolumns);
            //     }
            //     break;
            default:
                break;
        }
        
      };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "creator":
                setCreator(e.target.value);
                getData(filter_list, status, belong_type,info_type,e.target.value,perPage,page,search, skill_list, sort, column, order, search_skill);
                break;
            case "belong_type":
                setBelongType(e.target.value);
                getData(filter_list, status, e.target.value,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill);
                break;
            case "customer":
                setCustomer(e.target.value);
                getData(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill);
                break;
            case "saler":
                setSaler(e.target.value);
                getData(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill);
                break;
            case "sort":
                setSort(e.target.value);
                getData(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill_list, e.target.value, column, order, search_skill);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })

                break;
            default:
                break;
        }
     };

    

    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        // temp.page = this.state.page;
        getData(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill);

      };

     
      let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        }
    }

    const sendMail = () => {

        if(selectedRows.length == 0){
            alert("要員（30名以内でお願いします）をチェックしてください。");
            return false;
        }
        let company = (login_user != null)?login_user.company:"";
        var mail_message = "いつもお世話になっております、"+company+"の営業でございます。\n\n" +
        "日頃、要員アサインのご協力を頂きまして、\n" + 
        "心から感謝を申し上げます。\n" + 
        "あらためまして、案件情報を送付させて頂きます。\n" + 
        "ご協力いただける方がいらっしゃいましたら、\n" + 
        "ご提案を頂けますよう、お願い申し上げます。\n" + 
        　"※情報のお取扱いには、十分ご注意願います。\n" + 
        　"※ご質問・ご提案は、当該メールに「全員に返信」でお願い致します。\n" + 
        　"※技術者様の「所属」及び「並行営業状況」を記載してご提案いただくと助かります。";
        var sList = "";

        let ids : any[] = [];
        persons.forEach((element, index) => {            
            if (selectedRows.length > 0 && selectedRows.includes(index)) {
                ids.push(element["person_id"]);
            }
        });

        let data = {};
        data["ids"] = ids;
        PersonService.getBulkMailData(data).then((data)=>{
            data = data.data;
            for(var i=0; i < data.data.length; i++) {
                sList += "=================================\n";
                sList += "要員ID ： " + ((data.data[i].age != undefined && data.data[i].age != null)? data.data[i].person_id:"" )+ "、 ";
                sList += "要員名 ： " + ((data.data[i].InitialName != undefined && data.data[i].InitialName != null)?data.data[i].InitialName:"");
                sList += "\n";
                sList += "性別 ： " + ((data.data[i].gender != undefined && data.data[i].gender != null)?data.data[i].gender:'')+ "、 ";

                sList += "国籍 ： " + ((data.data[i].country != undefined && data.data[i].country != null)?data.data[i].country:'')+ "、 ";

                sList += "年齢 ： " + ((data.data[i].age != undefined && data.data[i].age != null)?data.data[i].age : 0)+ "歳";
                sList += "\n";
                sList += "最寄り駅 ： " + ((data.data[i].nearest_station != undefined && data.data[i].nearest_station != null)?data.data[i].nearest_station:'');
                sList += "\n";
                sList += "経験年数 ： " + ((data.data[i].prof != undefined && data.data[i].prof != null)?data.data[i].prof : 0)+ "年";
                sList += "、 来日年数 ： " + ((data.data[i].arrival != undefined && data.data[i].arrival != null) ? data.data[i].arrival : 0)+ "年";
                
                sList += "\n";
                sList += "日本語レベル ： " + ((data.data[i].japanese_level != undefined && data.data[i].japanese_level != null)?data.data[i].japanese_level:'');
                sList += "\n";
                sList += "英語レベル ： " + ((data.data[i].english_level != undefined && data.data[i].english_level != null)?data.data[i].english_level:'');
                sList += "\n";
                sList += "スキル ： " + ((data.data[i].skill != undefined && data.data[i].skill != null)?data.data[i].skill:'');
                sList += "\n";
                sList += "対応フェーズ ： " + ((data.data[i].response_phase != undefined && data.data[i].response_phase != null)?data.data[i].response_phase:'');
                sList += "\n";
                sList += "稼働可能日 ： " + ((data.data[i].workstart_date != undefined && data.data[i].workstart_date != null)?data.data[i].workstart_date:'');
                sList += "\n";
                sList += "希望単価 ： " +((data.data[i].proposal_price != undefined && data.data[i].proposal_price != null)?data.data[i].proposal_price: 0)+ "万";
                sList += "\n";
                sList += "特記事項 ： " + ((data.data[i].remarks != undefined && data.data[i].remarks != null)?data.data[i].remarks:'');
                sList += "\n\n";
                // personId += $(this).val() + ",";
                
            }

            mail_message = mail_message + "\n\n" + sList;
            mail_message += "\n以上、よろしくお願い致します。";
            mail_message += "\n\nーーーーーーーーーーーーーーーーーーーーーーーーーー\n";
            mail_message += "株式会社 FloBoard 営業部\n";
            mail_message += "担当: (野本)080-2264-1391\n";
            mail_message += "      (馮)080-5069-1827\n";
            mail_message += "MAIL: eigyou@floboard.co.jp\n";
            mail_message += "本社: 東京都千代田区東神田二丁目7番4-305\n";
            mail_message += "開発センター: 東京都江東区亀戸6-3-2 寺島ビル303室\n";
            mail_message += "TEL: (03)6753-0948 FAX: (03)6325-3608\n";
            mail_message += "URL: http://www.floboard.co.jp\n";
            mail_message += "一般労働者派遣: 派13-308001\n";
            mail_message += "Pマーク: 第10824573(01)号";
            mail_message += "\nーーーーーーーーーーーーーーーーーーーーーーーーーー";
            

            setState({
                ...state,
                send_mail_arr : {
                    personId : ids.join(","),
                    mail_to : "見込客の客先メールアドレスへ",
                    mail_subject : `【FloBoard要員】●●●●要員のご紹介`,
                    mail_message : mail_message,
                },
            })

            setOpenBulkModal(true);
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
               }
        });
    }

    const sendCustomerMail = () => {
      
        if(selectedRows.length < 1 || selectedRows.length > 30){
            alert("要員（30名以内でお願いします）をチェックしてください。");
            return false;
        }
        let name = (login_user != null)?login_user.name_jp: "";
        let company = (login_user != null)?login_user.company:"";
        let department = (login_user != null)?login_user.department: "";
        let phone = (login_user != null)? login_user.tel_no: "";
        let role = (login_user != null)? login_user.role: "";
        var mail_message =  "ご担当者様\nいつもお世話になっております、" + company + "の" +name + "です。" +
        "\n\nお忙しいところ恐縮ですが、"  + 
        "\n下記の注力要員をご提案させていただきます。"  + 
        "\nもし見合う案件がございましたら、" + 
        "\n是非ご検討、ご紹介のほどお願い申し上げます。" ;
        var sList = "";

        let ids : any[] = [];
        persons.forEach((element, index) => {            
            if (selectedRows.length > 0 && selectedRows.includes(index)) {
                ids.push(element["person_id"]);
            }
        });

        let data = {};
        data["ids"] = ids;
        PersonService.getBulkMailData(data).then((data)=>{
      
            data = data.data;
            for(var i=0; i < data.data.length; i++) {
                sList += "=================================\n";
                sList += "要員ID ： " + ((data.data[i].person_id != undefined && data.data[i].person_id != null)?data.data[i].person_id:'') + "、 ";
                sList += "要員名 ： " + ((data.data[i].full_name != undefined &&  data.data[i].full_name != null)?data.data[i].full_name:'');
                sList += "\n";
                sList += "性別 ： " + ((data.data[i].gender != undefined && data.data[i].gender !=null)?data.data[i].gender:'')+ "、 ";

                sList += "国籍 ： " + ((data.data[i].country != undefined && data.data[i].country != null)?data.data[i].country:'')+ "、 ";

                sList += "年齢 ： " + ((data.data[i].age != undefined && data.data[i].age != null)?data.data[i].age:'')+ "歳";
                sList += "\n";
                sList += "最寄り駅 ： " + ((data.data[i].nearest_station !=undefined && data.data[i].nearest_station != null)?data.data[i].nearest_station:'');
                sList += "\n";
                sList += "経験年数 ： " + ((data.data[i].prof != undefined && data.data[i].prof != null)?data.data[i].prof:'')+ "年";
                sList += "、 来日年数 ： " + ((data.data[i].arrival != undefined && data.data[i].arrival != null)?data.data[i].arrival:'')+ "年";
                
                sList += "\n";
                sList += "日本語レベル ： " + ((data.data[i].japanese_level!= undefined && data.data[i].japanese_level != null)?data.data[i].japanese_level:'');
                sList += "\n";
                sList += "英語レベル ： " + ((data.data[i].english_level != undefined && data.data[i].english_level != null)?data.data[i].english_level:'');
                sList += "\n";
                sList += "スキル ： " + ((data.data[i].skill != undefined && data.data[i].skill != null)?data.data[i].skill:'');
                sList += "\n";
                sList += "対応フェーズ ： " + ((data.data[i].response_phase !=undefined && data.data[i].response_phase != null)?data.data[i].response_phase:'');
                sList += "\n";
                sList += "稼働可能日 ： " + ((data.data[i].workstart_date != undefined && data.data[i].workstart_date != null)?data.data[i].workstart_date:'');
                sList += "\n";
                let  price1 = "";
                let remarks1 = "";
                if(data.data[i].proposal_price != null){
                    price1 = data.data[i].proposal_price;
                    if(data.data[i].proposal_price > 99999){
                        price1 = (data.data[i].proposal_price/10000).toFixed(2);
                        let pos = price1.lastIndexOf('.');
                        let point = parseInt(price1.substring(pos, 2));
                        if(point > 0){
                            price1 = price1  +"万";
                        }else{
                            price1 =  price1.substring(0, pos)+"万";
                        }
                        // price1 = (data.data[i].proposal_price/10000).toFixed(2) + "万";
                    }
                }
                if(data.data[i].remarks != null){
                    remarks1 = data.data[i].remarks;
                }
                sList += "希望単価 ： " + price1;
                sList += "\n";
                sList += "特記事項 ： " + remarks1;
                sList += "\n\n";
                // personId += $(this).val() + ",";
                
            }

            mail_message = mail_message + "\n\n" + sList;
            mail_message += "\nご質問・ご相談などがございましたら、";
            mail_message += "\nお気軽にお問合せください。";		
            mail_message += "\n\n以上、よろしくお願い致します。";
            mail_message += "\n\nーーーーーーーーーーーーーーーーーーーーーーーーーー";
            if(company != undefined && company != null){
                mail_message += "\n"+company;
            }

            mail_message += "\n"+((department != undefined && department != null)?department:'')+"  "+((name != undefined && name != null)?name:'');
            mail_message += "\n連絡先 ： " + ((phone != undefined && phone != null)?phone:'');
    
            if(role != 7) {
                mail_message += "\n本社: 東京都千代田区東神田二丁目7番4-305";
                mail_message += "\n開発センター: 東京都江東区亀戸6-3-2 寺島ビル303室";
                mail_message += "\nTEL: (03)6753-0948 FAX: (03)6325-3608";
                mail_message += "\nMAIL: sales@floboard.co.jp";	
                mail_message += "\n営業部:(馮)080-5069-1827,(野本)08022611391";
                mail_message += "\nURL: http://www.floboard.co.jp";	
                mail_message += "\n一般労働者派遣: 派13-308001";	
                mail_message += "\nPマーク: 第10824573(01)号";	
            }
    
            
            mail_message += "\nーーーーーーーーーーーーーーーーーーーーーーーーーー";
    
    
            // $('#mail-customer-modal').modal('show');
            // $('#to').val('');
            // $('#subject').val('【FloBoard要員】要員のご提案');
            // $('#message').val(mail_message);
            // $('#personID').val(personId);
            

            setState({
                ...state,
                send_customer_mail_arr : {
                    personId : ids.join(","),
                    mail_to : "",
                    mail_subject : `FloBoard要員】要員のご提案`,
                    mail_bcc: "",
                    mail_cc : send_customer_mail_arr.mail_cc,
                    attachment : send_customer_mail_arr.attachment,
                    mail_message : mail_message,
                },
            })

            setOpenModal(true);
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }

    const oneClickMail = () => {
        if(selectedRows.length < 1 || selectedRows.length > 30){
            alert("要員（30名以内でお願いします）をチャックしてください。");
            return false;
        }else{
            if(!window.confirm("1Clickで面談依頼(メール)しますか？")){
                return false;
            };
        }

        let name = (login_user != null)?login_user.name_jp: "";
        let company = (login_user != null)?login_user.company:"";
        let department = (login_user != null)?login_user.dept_name: "";
        let phone = (login_user != null)? login_user.tel_no: "";
        var mail_message =  "ご担当者 様\r\nいつもお世話になっております、" + company + "の" +name + "です。\r\n\r\n次のようにお問い合わせします。";
        var sList = "";
        let ids : any[] = [];
        persons.forEach((element, index) => {            
            if (selectedRows.length > 0 && selectedRows.includes(index)) {
                sList += "要員ID ： " + element["person_id"]+ "、 ";
                sList += "要員名 ： " + element["InitialName"];
                sList += "\r\n";
                // ids.push(element["person_id"]);
                // ids.push(element["InitialName"]);
            }
        });

        mail_message = mail_message + "\r\n\r\n" + sList;
        mail_message += "\r\n以上、よろしくお願い致します。";
		mail_message += "\r\n\r\nーーーーーーーーーーーーーーーーーーーーーーーーーー";
        if(company != undefined && company != null){
            mail_message += "\r\n"+company;
        }
        if(department != undefined && department != null){
            mail_message += "\r\n"+department;
        }
        if(name != undefined && name != null){
            mail_message += "\r\n"+name;
        }
        if(phone != undefined && phone != null){
            mail_message += "\r\n連絡先 ： " + phone;
        }
		mail_message += "\r\nーーーーーーーーーーーーーーーーーーーーーーーーーー";

        let formData = new FormData();    
        formData.append('mail_message', mail_message);
        let data = formData;
        PersonService.oneClickMailSend(data).then((response)=>{
            alert("メール送信いたしました。担当者から電話をお待ちください。ありがとうございます。");
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }

    const contactMail = () => {

        let name = (login_user != null)?login_user.name_jp: "";
        let company = (login_user != null)?login_user.company:"";
        let department = (login_user != null)?login_user.dept_name: "";
        let phone = (login_user != null)? login_user.tel_no: "";
        var mail_message =  "ご担当者 様\r\nいつもお世話になっております、" + company + "の" +name + "です。\r\n\r\n次のようにお問い合わせします。";
        var sList = "";
        let ids : any[] = [];
        persons.forEach((element, index) => {            
            if (selectedRows.length > 0 && selectedRows.includes(index)) {
                sList += "要員ID ： " + element["person_id"]+ "、 ";
                sList += "要員名 ： " + element["InitialName"];
                sList += "\r\n";
                // ids.push(element["person_id"]);
                // ids.push(element["InitialName"]);
            }
        });

        mail_message = mail_message + "\r\n\r\n" + sList;
        mail_message += "\r\n以上、よろしくお願い致します。";
		mail_message += "\r\n\r\nーーーーーーーーーーーーーーーーーーーーーーーーーー";
        if(company != undefined && company != null){
            mail_message += "\r\n"+company;
        }
        if(department != undefined && department != null){
            mail_message += "\r\n"+department;
        }
        if(name != undefined && name != null){
            mail_message += "\r\n"+name;
        }
        if(phone != undefined && phone != null){
            mail_message += "\r\n連絡先 ： " + phone;
        }

		mail_message += "\r\nーーーーーーーーーーーーーーーーーーーーーーーーーー";
        
        setState({
            ...state,
            send_contact_mail_arr : {
                mail_to : (login_user.role == 8)?"sales@floboard.co.jp, sales2@floboard.co.jp":"eigyou@floboard.co.jp",
                mail_subject : `営業システムからのお問い合わせ`,
                mail_cc : login_user.email,
                mail_message : mail_message,
            },
        })

        setContactMailOpen(true);
    }
   
    const handleSkill = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        const filter_skill = filter_list.filter((filter) => {
            return filter == "skill_list";
        });
        if(filter_skill.length == 0){
            filter_list.push("skill_list");
        }
        if(isChecked === true){
            skill_list.push(value);
            setSkillList(skill_list);
            getData(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill);
        }
        else{            
            const skill = skill_list.filter(function(skill){
                return skill != value; 
             });
             setSkillList(skill);
             getData(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill, sort, column, order, search_skill);
            }
    }

    const handleInfoType = function (e:any) {
        let isChecked = e.target.checked;

        let value = e.target.value;
        if(value == "belong" && isChecked == true){
            setInfoType(e.target.value);
            setColumnVisible(belongcolumns);
        }else{
            setInfoType(e.target.value);
            setColumnVisible(defaultcolumns);
        }
        setActiveInfoType(value);
        getData(filter_list, status, belong_type,e.target.value,creator,perPage,page,search, skill_list, sort, column, order, search_skill);
    }

    let location = useLocation();
    const goToDetail = function (colData:any, metaCell:any) {
        if(metaCell.colIndex){
            persons.forEach((element, index) => {
                if (metaCell.rowIndex == index ) {
                    localStorage.setItem('rootRoute', location.pathname);
                    localStorage.setItem("previousRoute" , location.pathname);
                    window.location.href = `/persons/${element["encode"]}`;
                }
            });
        }
        // let rows : any[] = [];
        // rowData.forEach((element:any, index:any) => {
        //     rows.push(element.dataIndex);
        // })
        // let ids : any[] = [];
        // cases.forEach((element, index) => {
        //   if (rows.includes(index)) {
        //     ids.push(element["case_id"]);
        //   }
        // });
        // CaseListService.delete(ids).then((response:any)=>{
        //     if(response.data.success == false){
        //         alert(response.data.info.message);
        //         window.location.reload();
        //     }
        // }).catch((error:any) => {
        //     setState({
        //         ...state,
        //         errorMessage:error.message
        //     })
        // })
    }
    // const options = {
    //     rowsPerPage: perPage,
    //     rowsPerPageOptions:[5,10,15,20],
    //     count: totalRows,
    //     serverSide: true,
    //     filter : false,
    //     print: false,
    //     viewColumns : false,
    //     search : false,
    //     download :false,
    //     textLabels: {
    //         body: {
    //           noMatch: "データはありません。",
    //           toolTip: "Sort"
    //         },
    //         pagination: {
    //           next: "Next Page",
    //           previous: "Previous Page",
    //           rowsPerPage: "件表示", 
    //           displayRows: "すべての件数：",
    //         },
    //       },
    //     onChangePage (currentPage:any) {
    //         changePage(currentPage);
    //     },
    //     onChangeRowsPerPage (numberOfRows:any) {
    //         changeRowsPerPage(numberOfRows);
    //     },
    //     onRowsDelete(rowData :any, rowState:any) {
    //         handleDelete(rowData, rowState);
    //      },
    // };

    const getMuiTheme = () =>
        createTheme({
          components: {
            MUIDataTableHeadCell: {
              styleOverrides: {
                root: {
                  background: "#c1e1ec",
                  position: "sticky"
                }
              }
            }
          }
        });

    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);

    const options = {
        filterType: 'checkbox' as any,
        tableBodyHeight : "360px",
        tableBodyMaxHeight : "360px",
        responsive: "standard" as any,
        selectableRows: "multiple" as any,
        // selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page : page,
        rowsPerPageOptions:[10,20,50,100,200],
        // selectableRowsOnClick: true  as any,
        selectableRowsHeader: true,
        fixedHeader: true,
        fixedSelectColumn: true,
        // resizableColumns: true,
        // draggableColumns: {
        //     enabled: true,
        // },
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        // onDownload: (buildHead:any, buildBody:any, columns:any, rows:any) => {
        //   },
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {
            const handleClick = () => {
            };
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                persons.forEach((element, index) => {
                    if (rows.includes(index)) {
                        csvData.push(element);
                    }
                });

                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "要員" +date;
                }

                let headers  : any[]= [];
                
                if(active_infotype == "base"){
                    headers =  [
                        { label: "要員ID", key: "person_id" },
                        { label: "要員ステータス", key: "assign_status" },
                        { label: "イニシャル", key: "InitialName" },
                        { label: "性別", key: "gender" },
                        { label: "国籍", key: "country" },
                        { label: "年齢", key: "age" },
                        { label: "最寄り駅", key: "nearest_station" },
                        { label: "経験年数", key: "prof"},
                        { label: "来日年数", key: "arrival" },   
                        { label: "日本語レベル", key: "japanese_level" },   
                        { label: "英語レベル", key: "english_level" }, 
                        { label: "スキル", key: "skill" }, 
                        { label: "対応フェーズ", key: "response_phase" }, 
                        { label: "新規稼働可能日", key: "workstart_date" }, 
                        { label: "希望単価", key: "proposal_price" }, 
                        { label: "特記事項", key: "interview_remarks" }, 
                        { label: "更新日", key: "update_date" }, 
                    ];
                }else{
                    headers =  [
                        { label: "要員ID", key: "person_id" },
                        { label: "イニシャル", key: "InitialName" },
                        { label: "漢字氏名", key: "full_name" },
                        { label: "所属種別", key: "belong_type" },
                        { label: "所属先ID", key: "belong_company_id" },
                        { label: "所属先担当者", key: "belong_contact_nm" },
                        { label: "所属先担当者メール", key: "belong_contact_email" },
                        { label: "所属先担当者電話", key: "belong_contact_tel"},
                        { label: "個人連絡先", key: "person_contact_info" },   
                        { label: "コスト", key: "person_cost" },   
                        { label: "更新日", key: "update_date" },   
                    ];
                }
               
              
            var now = new Date();
            if(login_user.role == 1 || login_user.role==2 || login_user.role == 7 || login_user.role == 8 || login_user.role == 9){
                return (
                    <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
                );
            }
            // return (
            //     <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            // );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
        
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        // onRowClick: (rowData:any, rowState:any) => {
        //    goToDetail(rowData, rowState);
        // },
        onCellClick : (colData:any, cellMeta:any) =>{
            goToDetail(colData, cellMeta);

        },
        // onRowsDelete(rowData :any, rowState:any) {
        //     handleDelete(rowData, rowState);
        // },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
        // onChangePage: (page) => {
        // this.setState({page}, () => {
        //     this.sort(this.state.sortOrder.name, this.state.sortOrder.direction);
        // });
        // }
    };
 
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    localStorage.setItem("person_list_search", e.target.value);
                    getData(filter_list, status, belong_type,info_type,creator,perPage,page, e.target.value, skill_list, sort, column, order, search_skill);
                break;
            case "search_skill":
                setSearchSkill(e.target.value);
                getData(filter_list, status, belong_type,info_type,creator,perPage,page, search, skill_list, sort, column, order, search_skill);
                break;
            default:
                break;
        }
        // setState({
        //     persons : [],
        //     errorMessage : "",
        //     page : 1,
        //     perPage : 10,
        //     column : "",
        //     order : "",
        //     totalRows : 0,
        //     creator_list : [],
        //     belong_type_list : [],
        //     isChecked: false,
        //     send_mail_arr : send_mail_arr,
        //     send_customer_mail_arr : {
        //         ...state.send_customer_mail_arr,
        //         [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
        //     }
        // });  
    };  

    let updateInput = (e:React.ChangeEvent<HTMLInputElement>):void => {
         setState({
            persons : persons,
            errorMessage : errorMessage,
            page : page,
            perPage : perPage,
            column : column,
            order : order,
            totalRows : totalRows,
            creator_list : creator_list,
            belong_type_list : belong_type_list,
            isChecked: true,
            send_mail_arr : {
                ...state.send_mail_arr,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            },
            send_contact_mail_arr:{
                ...state.send_contact_mail_arr,
                [e.target.name] : e.target.value
            },
            send_customer_mail_arr : send_customer_mail_arr,
            loading : loading 
        });
    }

    let updateTextArea = (e:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
           persons : persons,
           errorMessage : errorMessage,
           page : page,
           perPage : perPage,
           column : column,
           order : order,
           totalRows : totalRows,
           creator_list : creator_list,
           belong_type_list : belong_type_list,
           isChecked: true,
           send_mail_arr : {
               ...state.send_mail_arr,
               [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
           },
           send_contact_mail_arr : {
                ...state.send_contact_mail_arr,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            },
           send_customer_mail_arr : send_customer_mail_arr,
           loading : loading 
       });

   }

   let updateMailInput = (e:React.ChangeEvent<HTMLInputElement>):void => {
    
    setState({
       persons : persons,
       errorMessage : errorMessage,
       page : page,
       perPage : perPage,
       column : column,
       order : order,
       totalRows : totalRows,
       creator_list : creator_list,
       belong_type_list : belong_type_list,
       isChecked: true,
       send_mail_arr : send_mail_arr,
       send_contact_mail_arr : send_customer_mail_arr,
       send_customer_mail_arr : {
            ...state.send_customer_mail_arr,
            [e.target.name] : e.target.value 
       },
       loading : loading 
   });
}

let updateMailTextarea = (e:React.ChangeEvent<HTMLTextAreaElement>):void => {
   setState({
      persons : persons,
      errorMessage : errorMessage,
      page : page,
      perPage : perPage,
      column : column,
      order : order,
      totalRows : totalRows,
      creator_list : creator_list,
      belong_type_list : belong_type_list,
      isChecked: true,
      send_mail_arr : send_mail_arr,
      send_contact_mail_arr : send_contact_mail_arr,
      send_customer_mail_arr : {
            ...state.send_customer_mail_arr,
            [e.target.name] : e.target.value 
       },
       loading : loading 
  });

}
    
    const handleStatus = function(status:any){
        setActive(status);
        setStatus(status);
        // const filter_status = filter_list.filter((filter) => {
        //     return filter == "status";
        // });
        // if(filter_status.length == 0){
        //     filter_list.push("status");
        // }
        localStorage.setItem("person_list_status", status);
        getData(filter_list, status, belong_type,info_type,creator,perPage,page,search, skill_list, sort, column, order, search_skill);
        // getData(filter_list,sale,hr,status,skill_list,search,sort);
    }

    const deletePerson = () => {
        if(selectedRows.length == 0){
            alert("削除したい要員をチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            persons.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["person_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            PersonService.deleteList(data).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }else{
                    window.location.reload();
                }
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                   }

                });
        }
       
    }


    const customer_mail_modal = (
        <div>
              <div className="row">
                    <div className="col-12">
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </div>            
                </div>
                <h3>要員提案のメール送信</h3>
                <form className="form" onSubmit={customerEmailSend}>
                    <input type="hidden" name="personId" value={send_customer_mail_arr["personId"]} />
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">件名:</label> 
                        <input type="text" className="form-control" name="mail_subject"  value={send_customer_mail_arr['mail_subject']}  onChange={updateMailInput}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">TO:</label> 
                        <input type="text" className="form-control" name="mail_to"  value={send_customer_mail_arr['mail_to']}  onChange={updateMailInput}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">BCC:</label> 
                        <input type="text" className="form-control" name="mail_bcc"  value={send_customer_mail_arr['mail_bcc']}  onChange={updateMailInput}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="input-required">CC:</label> 
                        <input type="text" className="form-control" name="mail_cc"  value={send_customer_mail_arr['mail_cc']}  onChange={updateMailInput}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">メール内容:</label> 
                        <textarea name="mail_message" rows={12} cols={40} className="form-control"  value={send_customer_mail_arr['mail_message']} id="send_mail_model_text" onChange={updateMailTextarea}>
                        </textarea>
                    </div> 
                    <div className="form-group text-center">
                        <input type="checkbox"  name="attachment"/><br/>
                        <label htmlFor="exampleInputEmail1">履歴書も添付する</label> 
                    </div>                    
                    <div className="row">
                        <div className="form-group mt-5 text-center">
                            <button type="reset" className="button modal_cancel_btn" onClick={handleClose}>取消</button>
                            <button type="submit" className="button submit-btn btn_standard">送信</button>
                        </div>
                    </div>
                    {/* <CustomerMailModal data={send_customer_mail_arr} /> */}
                </form>
        </div>
    )

    const bulk_mail_modal = (
        <div>
             <div className="row">
                    <div className="col-12">
                         <button type="button" className="modal_close" onClick={handleBulkModalClose}>X</button>
                    </div>            
                </div>
                <h3>一括メール送信</h3>
                <form className="form" onSubmit={emailSend}>
                    {/* <BulkMailModal data={send_mail_arr} /> */}
                    <input type="hidden" name="personId" value={send_mail_arr["personId"]} />
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">件名:</label> 
                        <input type="text" className="form-control" name="mail_subject"  value={send_mail_arr['mail_subject']} onChange={updateInput}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">TO:</label> 
                        <input type="text" className="form-control" name="mail_to"  value={send_mail_arr['mail_to']} disabled/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">メール内容:</label> 
                        <textarea name="mail_message" rows={12} cols={40} className="form-control"  value={send_mail_arr['mail_message']} onChange={updateTextArea} >
                        </textarea>
                    </div> 
                    <div className="form-group text-center">
                        <input type="checkbox"  name="attachment"/><br/>
                        <label htmlFor="exampleInputEmail1">履歴書も添付する</label> 
                    </div>                    
                    <div className="row">
                        <div className="form-group mt-5 text-center">
                            <button type="submit" className="button modal_cancel_btn">取消</button>
                            <button type="submit" className="button submit-btn btn_standard">送信</button>
                        </div>
                    </div>
                </form>
        </div>
    )

    const contact_mail_modal = (
        <div>
            <div className="row">
                <div className="col-12">
                        <button type="button" className="modal_close" onClick={handlecontactMailModalClose}>X</button>
                </div>            
            </div>
            <h3>お問い合わせのメール送信</h3>
            <form className="form" onSubmit={contactEmailSend}>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">件名:</label> 
                    <input type="text" className="form-control" name="mail_subject"  value={send_contact_mail_arr['mail_subject']} onChange={updateInput}/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">TO:</label> 
                    <input type="text" className="form-control" name="mail_to"  value={send_contact_mail_arr['mail_to']} onChange={updateInput} />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="input-required">CC:</label> 
                    <input type="text" className="form-control" name="mail_cc"  value={send_contact_mail_arr['mail_cc']} onChange={updateInput}/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">メール内容:</label> 
                    <textarea name="mail_message" rows={12} cols={40} className="form-control"  value={send_contact_mail_arr['mail_message']} onChange={updateTextArea} >
                    </textarea>
                </div>                 
                <div className="row">
                    <div className="form-group mt-5 text-center">
                        <button type="reset" className="button modal_cancel_btn" onClick={handlecontactMailModalClose}>取消</button>
                        <button type="submit" className="button submit-btn btn_standard">送信</button>
                    </div>
                </div>
            </form>
        </div>
    )
   
    return(
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>

                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" value={search} placeholder = {(login_user.role!=1 && login_user.role!=2 && login_user.role!=8)?"検索(要員ID、イニシャル、漢字氏名、カタカナ氏名、英語氏名、所属先、国籍、最寄り駅、特記事項を検索)":"検索(要員ID、イニシャル、漢字氏名、カタカナ氏名、英語氏名、国籍、最寄り駅、特記事項を検索)"}
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                    <Row>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                            <h3 className='header-left-design'>要員一覧</h3>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="float-end">
                            {
                                ([3,4,7,9].includes(login_user.role))
                            }
                            <div className="mt-4">
                            {
                                (() => {
                                    if(login_user.role ==3 || login_user.role == 4|| login_user.role == 7 ||login_user.role == 9) {
                                    return(<Link to={`/persons/create`} className="btn_add">
                                    要員追加
                                    </Link>)
                                    }
                                })()
                                }
                            </div>
                        </Col>
                </Row> 
                <Row>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                        <ul className="status-menu responsive-list filter" data-filter="status">
                        {status_list.map(status => (
                            <li className= {"list-item " +((status[0] == active)?active:"")}>
                            <a onClick={() => handleStatus(status[0])}>
                                {status[1]}
                            </a>
                            </li>
                        ))}
                        {status_list1.map(status => (
                            <li className={"list-item " +((status[0] == active)?active:"")}>
                            <a onClick={() => handleStatus(status[0])}>
                                {status[1]}
                            </a>
                            </li>
                        ))}
                        
                        {
                            (() => {
                                if(login_user.role !=7 && login_user.role!=8) {
                                    return( <li className={"list-item " +((('otsu_not_public' == active)?active:"") + ((otsu_not_public_count > 0)? ' data-exist' : ' no-data'))}>
                                    <a onClick={() => handleStatus('otsu_not_public')}>
                                    乙方登録未公開
                                    </a>
                                </li>)
                                }
                            })()
                        }

                        {
                            (() => {
                                if(login_user.role !=7 && login_user.role!=8) {
                                    return(  <li className={"list-item " +((('otsu_public' == active)?active:"") + ((otsu_public_count > 0)? ' data-exist' : ' no-data'))}>
                                    <a onClick={() => handleStatus('otsu_public')}>
                                    乙方登録公開済
                                    </a>
                                </li>)
                                }
                            })()
                        }

                        </ul>
                    </Col>
                </Row>
                <Row>
                        <Col xxl={7} xl={7} md={7} sm={7} xs={12}>
                            <ul className="status-menu responsive-list filter" data-filter="status">
                            {status_list2.map(status => (
                                <li className={(status[0] == active)?active:""}>
                                <a onClick={() => handleStatus(status[0])}>
                                    {status[1]}
                                </a>
                                </li>
                            ))}
                            </ul>
                            <ul className="status-menu filter" data-filter="status">
                            {status_list3.map(status => (
                            <li className={((status[0] == active)?active:"")+ ((person_count > 0)? ' data-exist' : ' no-data')}>
                            <a onClick={() => handleStatus(status[0])}>
                                {status[1]}({person_count})
                            </a>
                            </li>
                        ))}
                            </ul>
                        </Col>
                        <Col xxl={5} xl={5} md={5} sm={5} xs={12}>
                            <div className='float-right'>
                            {
                            (() => {
                                if(login_user.role ==1 || login_user.role==2 || login_user.role==8 || login_user.role==9){
                                    return(
                                        <React.Fragment>
                                            
                                                <button type="button"  className="btn_standard btn-sm float-end xs-hidden" onClick={() => {oneClickMail();}}>1クリックで面談依頼</button>
                                            
                                          
                                                <button type="button"  className="btn_standard btn-sm float-end xs-hidden" onClick={() => {contactMail();}} style={{marginRight:"22px"}}>お問い合わせ</button>
                                          
                                        </React.Fragment>
                                    )
                                }
                            }
                            )()
                        } 
                        
                            </div>

                        </Col>                        
                    </Row>
                   
                    <Row className="g-0">
                        <Col xxl={10} xl={8} md={8} sm={8} xs={12} >
                            <div className="check-list filter" id="filter-by-skill">
                                <input type="checkbox" id="java" name="java" value="java" onChange={e => handleSkill(e)} />
                                <label htmlFor="java"> JAVA</label>
                                <input type="checkbox" id="c" name="c" value="c" onChange={e => handleSkill(e)} />
                                <label htmlFor="c"> C/C++</label>
                                <input type="checkbox" id="asp" name="asp" value="asp" onChange={e => handleSkill(e)} />
                                <label htmlFor="asp"> ASP.NET/C#</label>
                                <input type="checkbox" id="vb" name="vb" value="vb" onChange={e => handleSkill(e)}/>
                                <label htmlFor="vb"> VB/VB.NET</label>
                                <input type="checkbox" id="php" name="php" value="php" onChange={e => handleSkill(e)}/>
                                <label htmlFor="php"> PHP</label>
                                <input type="checkbox" id="ruby_python" name="ruby" value="ruby_python" onChange={e => handleSkill(e)}/>
                                <label htmlFor="ruby_python"> Ruby/Python</label>
                                <input type="checkbox" id="sap" name="sap" value="sap"  onChange={e => handleSkill(e)}/>
                                <label htmlFor="sap"> SAP</label>
                                <input type="checkbox" id="plsql" name="plsql" value="plsql" onChange={e => handleSkill(e)} />
                                <label htmlFor="plsql"> PLSQL(PL/SQL)</label>
                                <input type="checkbox" id="other" name="other" value="infra" onChange={e => handleSkill(e)} />
                                <label htmlFor="other"> インフラ</label>
                                <input type="checkbox" id="handoutai" name="handoutai" value="handoutai" onChange={e => handleSkill(e)} />
                                <label htmlFor="handoutai"> 半導体</label>
                                <input type="text" placeholder="and スキルを検索" name="search_skill" id="search_skill" className="form_control" onChange={onChange} style={{borderRadius:"15px !important",height:"30px"}}/>
                            </div>
                        </Col>
                        <Col xxl={2} xl={4} md={4} sm={4} xs={12} className="xs-hidden">
                            <div className="float-right">
                                <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                                <option value="10">10</option>
                                                <option value="20" >20</option>
                                                <option value="50" >50</option>
                                                <option value="100" >100</option>
                                                <option value="200" >200</option>
                                </select>
                                <label htmlFor="sort" className="control-label">件表示</label>
                                {/* <label htmlFor="sort" className="control-label ml_20 ">すべての件数：{totalRows}件</label> */}
                                <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                            previousPage();
                                            }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                        </button>
                                        <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                            nextPage();
                                            }}>
                                            <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                </button> 
                            </div>
                        </Col>                        
                    </Row>
                    <Row className="pt-2 pb-2">
                        <Col xxl={3} xl={3} md={3} sm={3} xs={12} className="xs-hidden">
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                        </Col>
                        <Col xxl={9} xl={9} md={9} sm={9} xs={12} className="filter_select ">
                                <div className="float-right xs-disable-float">
                                    {
                                           (() => {
                                                        if(login_user.role ==7){
                                                            return(
                                                                <React.Fragment>
                                                                <input type="radio" id="radio_type_base" name="info_type" checked= {(info_type == 'base')? true: false}  value="base" onChange={e => handleInfoType(e)} />
                                                                <label htmlFor="base" className='pr-15 pl-4'>基本情報</label>
                                                                </React.Fragment>
                                                                );
                                                        }else{
                                                            return(
                                                                <React.Fragment>
                                                                <input type="radio" id="radio_type_base" name="info_type" value="base" checked= {(info_type == 'base')? true: false} onChange={e => handleInfoType(e)} />
                                                                <label htmlFor="radio_type_base" className='pr-15 pl-4'>基本情報</label>
                                                                <input type="radio" id="radio_type_belong" style={{display:(![1,2,5,8].includes(login_user.role))?'':'none'}} name="info_type" value="belong" checked= {(info_type == 'belong')? true: false} onChange={e => handleInfoType(e)} />
                                                                <label htmlFor="radio_type_belong" className="mr-3 pl-4" style={{display:(![1,2,5,8].includes(login_user.role))?'':'none'}}>所属情報</label>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                })()
                                    }
                                        {/* <input type="radio" id="radio_type_base" name="info_type" value="base" onChange={e => handleInfoType(e)} />
                                        <label htmlFor="base">基本情報</label> */}
                                        {/* <input type="radio" id="radio_type_base" name="info_type" value="belong" onChange={e => handleInfoType(e)} />
                                        <label htmlFor="base" className="mr-3">所属情報</label> */}
                                    {
                                                (() => {
                                                        if(login_user.role !=7 && login_user.role !=8){
                                                            return(
                                                                <label htmlFor="sort" className="control-label ml_20">所属</label>
                                                                )
                                                        }
                                                })()
                                    }
                                    {/* <label htmlFor="sort" className="control-label ml_20">所属</label> */}
                                    {
                                                (() => {
                                                        if(login_user.role !=7 && login_user.role !=8){
                                                            return(
                                                                <select className="filter" name="belong_type" aria-label=".form-select-sm example" onChange={onSelect}>
                                                                    {belong_type_list.map((b_list:any) => {
                                                                            return(<option value={b_list}> {b_list}</option>)
                                                                        })
                                                                    }
                                                                </select> 
                                                                )
                                                        }
                                                })()
                                    }
                                    {/* <select className="filter" name="belong_type" aria-label=".form-select-sm example" onChange={onSelect}>
                                    {belong_type_list.map((b_list:any) => {
                                            return(<option value={b_list}> {b_list}</option>)
                                        })
                                    }
                                    </select>  */}
                                    {
                                                (() => {
                                                        if(![8, 3, 7].includes(login_user.role)){
                                                            return(
                                                                <label htmlFor="sort" className="control-label xs-hidden" style={{paddingLeft:"10px"}}>登録者</label> 
                                                                )
                                                        }
                                                })()
                                    }
                                    {
                                                (() => {
                                                        if(![8, 3, 7].includes(login_user.role)){
                                                            return(
                                                                <select className="filter xs-hidden" name="creator" aria-label=".form-select-sm example" onChange={onSelect}>
                                                                {creator_list.map(creator => {
                                                                        return(<option value={(creator[1] == "全て")?"全て":creator[0]}> {creator[1]}</option>)
                                                        })
                                                    }
                                                            </select>
                                                                )
                                                        }
                                                })()
                                    }
                                   
                                    {
                                                (() => {
                                                        if(login_user.role !=8){
                                                            return(
                                                                <button type="button" className="btn_standard ml_20 btn-sm xs-hidden" onClick={() => {
                                                                    sendCustomerMail();
                                                                    }}>メール提案
                                                                </button>  
                                                            )
                                                        }
                                                })()
                                    }
                                    {
                                         (() => {
                                            if(login_user.role ==1 || login_user.role==2 || login_user.role ==9){
                                                return(
                                                    <button type="button" className="btn_standard ml_20 btn-sm xs-hidden" onClick={() => {
                                                        sendMail();
                                                        }}>一括メール提案
                                                    </button>
                                                )
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if(login_user.role !=8){
                                                return(
                                                    <button type="button" className="btn_danger ml_20 btn-sm xs-hidden" onClick={deletePerson}>削除</button>
                                                )
                                            }
                                        }
                                        )()
                                    }
                                   
                                    {/* <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                        sendCustomerMail();
                                        }}>メール提案
                                    </button> 
                                    */}
                                    {/* <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                        sendMail();
                                        }}>一括案件送信
                                    </button> */}
                                </div>
                        </Col>
                    </Row>
                    <Row className="pt-2 pb-2 g-0 m-0 pc-hidden ">
                        <Col  xs={5}>
                            <button type="button"  className="btn_standard btn-sm float-end" onClick={() => {oneClickMail();}}>1クリックで面談依頼</button>
                        </Col>
                        <Col  xs={4}>
                            <button type="button"  className="btn_standard btn-sm float-end" onClick={() => {contactMail();}} style={{marginRight:"22px"}}>お問い合わせ</button>
                        </Col>
                        <Col xs={3}>
                        {
                                    (() => {
                                            if(login_user.role !=8){
                                                return(
                                                    <button type="button" className="btn_standard ml_20 btn-sm pc-hidden" onClick={() => {
                                                        sendCustomerMail();
                                                        }}>メール提案
                                                    </button>  
                                                )
                                            }
                                    })()
                        }
                        </Col>
                    </Row>
                    <Row className="pt-2 pb-2 pc-hidden">
                        <Col xs={12}>
                        {
                            (() => {
                                    if(![8, 3, 7].includes(login_user.role)){
                                        return(
                                            <label htmlFor="sort" className="control-label pc-hidden" style={{paddingLeft:"10px"}}>登録者</label> 
                                            )
                                    }
                                    })()
                        }
                        {
                                    (() => {
                                            if(![8, 3, 7].includes(login_user.role)){
                                                return(
                                                    <select className="filter pc-hidden" name="creator" aria-label=".form-select-sm example" onChange={onSelect}>
                                                    {creator_list.map(creator => {
                                                            return(<option value={(creator[1] == "全て")?"全て":creator[0]}> {creator[1]}</option>)
                                            })
                                        }
                                                </select>
                                                    )
                                            }
                                    })()
                        }
                        
                        {
                                (() => {
                                if(login_user.role ==1 || login_user.role==2 || login_user.role ==9){
                                    return(
                                        <button type="button" className="btn_standard ml_20 btn-sm pc-hidden" onClick={() => {
                                            sendMail();
                                            }}>一括メール提案
                                        </button>
                                    )
                                }
                            })()
                        }
                          {
                                        (() => {
                                            if(login_user.role !=8){
                                                return(
                                                    <button type="button" className="btn_danger ml_20 btn-sm" onClick={deletePerson}>削除</button>
                                                )
                                            }
                                        }
                                        )()
                                    }
                        </Col>
                    </Row>
                    <Row className="pt-2 pb-2">
                        <Col xxl={3} xl={3} md={3} sm={3} xs={12} className="pc-hidden">
                            <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                <option value="0" selected>新しい順</option>
                                <option value="1">古い順</option>
                            </select>
                            <div className="float-right">
                                <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                                <option value="10">10</option>
                                                <option value="20" >20</option>
                                                <option value="50" >50</option>
                                                <option value="100" >100</option>
                                                <option value="200" >200</option>
                                </select>
                                <label htmlFor="sort" className="control-label">件表示</label>
                                {/* <label htmlFor="sort" className="control-label ml_20 ">すべての件数：{totalRows}件</label> */}
                                <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                            previousPage();
                                            }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                        </button>
                                        <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                            nextPage();
                                            }}>
                                            <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                </button> 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                            <div className='datatable freeze-sale-header freeze-sale-cell frozen-columns person_list xs-hidden'>
                                <MUIDataTable
                                    title={''}
                                    data={persons}
                                    columns={columnVisible}
                                    options = {options}
                                    
                                />
                            </div>            
                        </Col>
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                            <div className='datatable mobile pc-hidden'>
                                <MUIDataTable
                                    title={''}
                                    data={persons}
                                    columns={columnVisible}
                                    options = {options}
                                />
                            </div>            
                        </Col>
                    </Row>
                   
                </Container>
        
        <Modal
            keepMounted
            open={bulkOpen}
            onClose={handleBulkModalClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}>
               {bulk_mail_modal}
            </Box>
        </Modal>
        <Modal
            keepMounted
            open={bulkOpen}
            onClose={handleBulkModalClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}>
               {bulk_mail_modal}
            </Box>
        </Modal>
        <Modal
            keepMounted
            open={contactMailOpen}
            onClose={handlecontactMailModalClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}>
               {contact_mail_modal}
            </Box>
        </Modal>
        <Modal
            keepMounted
            open={contactMailOpen}
            onClose={handlecontactMailModalClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}>
               {contact_mail_modal}
            </Box>
        </Modal>
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}>
               {customer_mail_modal}
            </Box>
        </Modal>
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}>
               {customer_mail_modal}
            </Box>
        </Modal>
        </React.Fragment>
    );
};
export default PersonList;